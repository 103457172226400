<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.professional-background.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="backgrounds"
              :sort-mapping="sortMapping"
              :title="'professional background'"
              :total="backgroundsTotal"
              :fetch-path="'backgrounds/fetch'"
              :filter-path="'backgrounds/filter'"
              :export-path="'backgrounds/export'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View professional background
                      </b-dropdown-item>
                      <b-dropdown-item @click="$router.push({name: 'researcher.professional-background.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Institution</label>
                  <v-select
                    v-if="institutions.length !== undefined"
                    label="name"
                    :options="institutions"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'institution')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Publish in web</label>
                  <select
                    class="form-select"
                    @input="saveFilter($event.target.value, 'publish_web')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="Publish in web"
                    >
                      Yes
                    </option>
                    <option
                      value="Don't publish in web"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Year</label>
                  <date-picker
                    v-model="filters['year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Current host</label>
                  <select
                    class="form-select"
                    @input="saveFilter($event.target.value, 'current_position')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="1"
                    >
                      Yes
                    </option>
                    <option
                      value="0"
                    >
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div
                  v-if="item.id"
                  class="offcanvas-body offcanvas-body--view"
                >
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Researcher<br>
                      <strong>{{ item.user.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Position<br>
                      <strong>{{ item.title }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Current host<br>
                      <strong
                        class="badge"
                        :class="item.current_position ? 'badge-light-success' : 'badge-light-danger'"
                      >{{ item.current_position ? 'Yes' : 'No' }}</strong></p>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p>Begin date<br>
                        <strong>{{ item.start_date }}</strong></p>
                    </div>
                    <div class="col">
                      <p>End date<br>
                        <strong>{{ item.end_date }}</strong></p>
                    </div>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <h4>Institution / department</h4>
                    <p><strong>{{ item.institution.name }}</strong></p>
                    <a
                      data-bs-toggle="collapse"
                    >
                      <span
                        id="open-location"
                        v-b-toggle="`collapseLocation`"
                        @click="loadMap(item.institution)"
                      >
                        Open location <i data-feather="chevron-down" />
                      </span>
                    </a>
                    <b-collapse
                      :id="`collapseLocation`"
                    >
                      <div class="card shadow-none bg-light">
                        <div class="card-body">
                          <p>Address<br>
                            <strong>{{ item.institution.address }}</strong></p>
                          <p>Postal code<br>
                            <strong>{{ item.institution.postal_code }}</strong></p>
                          <p>City<br>
                            <strong>{{ item.institution.city }}</strong></p>
                          <p>Country<br>
                            <strong>{{ item.institution.country }}</strong></p>
                          <p>Web<br>
                            <strong><a
                              href="https://www.pmt.es"
                              target="_blank"
                            >WIP</a> </strong></p>
                          <div
                            v-if="item.institution.latitude && item.institution.longitude"
                            id="institutionMap"
                            class="mt-1"
                            style="width:100%;height:35vh;"
                          />
                          <a
                            id="copy-address"
                            class="mt-2 d-inline-block"
                            @click="copyAddress(item.institution)"
                          ><i data-feather="copy" /> Copy address</a>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <hr>
                  <h4>Professional info</h4>
                  <div class="mb-1">
                    <p>Email<br>
                      <strong><a :href="`mailto:${item.professional_email}`">{{ item.professional_email }}</a></strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Phone<br>
                      <strong>{{ item.user.phone }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Cell phone<br>
                      <strong>{{ item.mobile_phone }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Publish in web<br>
                      <strong
                        class="badge"
                        :class="item.published_web ? 'badge-light-success' : 'badge-light-danger'"
                      >{{ item.published_web ? 'Yes' : 'No' }}</strong></p>
                  </div>

                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    title="edit this professional background"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="$router.push({name: 'researcher.professional-background.edit', params: { id: item.id }})"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCollapse,
    DatePicker,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Position',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Position',
          checked: true,
          order: 2,
        },
        {
          name: 'Institution',
          checked: true,
          order: 3,
        },
        {
          name: 'Department',
          checked: true,
          order: 4,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 5,
        },
        {
          name: 'End date',
          checked: true,
          order: 6,
        },
        {
          name: 'Country',
          checked: true,
          order: 7,
        },
        {
          name: 'Current host',
          checked: true,
          order: 8,
          only_admin: true,
        },
        {
          name: 'Current host 2n',
          checked: true,
          order: 9,
          only_admin: true,
        },
        {
          name: 'Publish in web',
          checked: true,
          order: 10,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Country: 'country_table',
        Position: 'title',
        Institution: 'institution_table',
        Department: 'department_table',
        'Begin date': 'start_date',
        'End date': 'end_date_table',
        'Current host': 'current_position',
        'Current host 2n': 'current_position_2_table',
        'Publish in web': 'published_web',
      },
      title: 'Professional Background',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      backgrounds: 'backgrounds/backgrounds',
      backgroundsTotal: 'backgrounds/backgroundsTotal',
      institutions: 'institutions/institutions',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filtersAux: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('backgrounds/filter', this.filtersAux)
    await this.$store.dispatch('modals/fetchUserFields', 'professional-background')

    // load institutions if they are not loaded already
    if (!this.institutions || this.institutions.length === 0) {
      await this.$store.dispatch('institutions/filter', this.$store.getters['filters/filters'])
    }

    // load fields if they are not loaded already
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'professional-background',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    loadMap(data) {
      // The location
      const uluru = { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }
      // The map, centered at Uluru
      const map = new google.maps.Map(
        document.getElementById('institutionMap'),
        {
          zoom: 13,
          center: uluru,
        },
      )

      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: uluru,
        map,
      })
    },
    copyAddress(institution) {
      navigator.clipboard.writeText(`${institution.address}, ${institution.city} ${institution.postal_code}, ${institution.country}`)
      this.$toastr.success('', 'Copied to clipboard')
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'backgrounds', url: `${Vue.prototype.$groupUrl}/backgrounds/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
